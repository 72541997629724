export default {
  tabbar: {
    首页: '首页',
    市场: '市场',
    任务: '任务',
    团队: '团队',
    我的: '我的'
  },
  navbar: {
    充值: '充值',
    提现: '提现',
    帮助: '帮助',
    分享: '分享',
    活动: '活动'
  },	 
  payment: {
    付款方式详情: '付款方式详情',
    银行卡: '银行卡',
    钱包地址: '钱包地址',
    交易密码: '交易密码',
    保存: '保存'
  },
  market: {
    市场: '市场',
    名称: '名称',
    价格: '价格',
  },
 
  task: {
    任务: '任务',
	语言切换: '语言切换',
    我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  团队: '团队',
  规则: '规则',
  会员级别: '会员级别',
  经验: '经验',
  代理信息: '代理信息',
  更多: '更多',
  团队奖励: '团队奖励',
  昨天: '昨天',
  本星期: '本星期',
  全部: '全部',
  团队收入记录: '团队收入记录',
  机器人回扣: '机器人回扣',
  会员体验: '会员体验',
  个人收入记录: '个人收入记录',
  机器人采购: '机器人采购',
  机器人启动押金: '机器人启动押金',
  机器人礼物: '机器人礼物',
  机器人订单: '机器人订单',
  机器人: '机器人',
  一次性总和: '一次性总和',
  总计: '总计',
  我的机器人: '我的机器人',
  被激活: '被激活',
  工作中: '工作中',
  暂停: '暂停',
  结束: '结束',
  刷新: '刷新',
  来源: '来源',
  价格: '价格',
  启动押金: '启动押金',
  剩余时间: '剩余时间',
  激活时间: '激活时间',
  号: '号',
  我的资产: '我的资产',
  退出: '退出',
  邀请码: '邀请码',
  我的钱包余额: '我的钱包余额',
  我的机器人仓库: '我的机器人仓库',
  代理信息: '代理信息',
  去提款: '去提款',
  昨天代理返利: '昨天代理返利',
  累计代理返利: '累计代理返利',
  今天机器人收益: '今天机器人收益',
  累计机器人收益: '累计机器人收益',
  累计机器人回扣: '累计机器人回扣',
  累计总回报: '累计总回报',
  自动订单机器人数量: '自动订单机器人数量',
  我的付款方式: '我的付款方式',
  个人收入记录: '个人收入记录',
  团队收入记录: '团队收入记录',
  充值记录: '充值记录',
  我的团队成员: '我的团队成员',
  活动中心: '活动中心',
  修改登录密码: '修改登录密码',
  交易密码管理: '交易密码管理',
  机器人: '机器人',
  机器人性能: '机器人性能',
  机器人价格: '机器人价格',
  启动押金: '启动押金',
  有效期: '有效期',
  号: '号',
  天: '天',
  去购买: '去购买',
  买个机器人: '买个机器人',
  我的钱包余额: '我的钱包余额',
  预计日收益: '预计日收益',
  启动保证金: '启动保证金',
  请输入购买数量: '请输入购买数量',
  个机器人: ' 个/机器人',
  机器人购买后需要激活才能工作: '机器人购买后需要激活才能工作',
  机器人激活需要从余额中扣除相应的启动押金:
    '机器人激活需要从余额中扣除相应的启动押金',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    '机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送',
  购买: '购买',
  点击去了解我们: '点击去了解我们',
  购买机器人: '购买机器人',
  快速入口: '快速入口',
  '日/个人受益': '日/个人受益',
  购买教程: '购买教程',
  更多: '更多',
  机器人性能: '机器人性能',
  机器人价格: '机器人价格',
  启动押金: '启动押金',
  订单数量: '订单数量',
  我们的合作伙伴: '我们的合作伙伴',
  提款: '提款',
  提款金额: '提款金额',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: '提现说明',
  确认提款: '确认提款 ',
  钱包余额: '钱包余额',
  输入数量:"输入数量",
  数量:"数量",
  登录: '登录',
  输入账号: '输入账号',
  输入密码: '输入密码',
  还没有账号点击注册: '还没有账号 ？ 点击注册',
  忘记密码: '忘记密码',
  重新登录: '重新登录',
  密码找回成功: '密码找回成功',
  找回成功请重新登录: '找回成功 请重新登录',
  确认: '确认',
  发送验证码: '发送验证码',
  再次输入密码: '再次输入密码',
  输入验证码: '输入验证码',
  输入手机号: '输入手机号',
  注册: '注册',
  输入用户名: '输入用户名',
  请输入邀请码: '请输入邀请码',
  返回: '返回',
  密码找回失败: '密码找回失败',
  输入的信息有误: '输入的信息有误',
	活动中心: '活动中心',
	获取验证码:"获取验证码",
	手机号码不能为空:"手机号码不能为空",
	账号不存在:"账号不存在",
	发送成功:"发送成功",
	今日收益:"今日收益",
	有效期:"有效期",
	天:"天",
	日收益:"日收益",
	请输入购买数量:"请输入购买数量",
	数量不能为空:"数量不能为空",
	加载中:"加载中",
	唯一码:"唯一码",
	未激活:"未激活",
	激活:"激活",
	购买时间:"购买时间",
	"钱包余额不足，激活该机器人失败":"钱包余额不足，激活该机器人失败",
	暂无数据:"暂无数据",
	激活时间:"激活时间",
	运行时长:"运行时长",
	签约购买:"签约购买",
	系统赠送:"系统赠送",
	状态:"状态",
	正常:"正常",
	我的机器人:"我的机器人",
	一级会员:"一级会员",
	二级会员:"二级会员",
	三级会员:"三级会员",
	人:"人",
	充值客服:"充值客服",
	充值:"充值",
	提现:"提现",
	设置交易密码:"设置交易密码",
	登录密码:"登录密码",
	请输入交易密码:"请输入交易密码",
	再次请输入交易密码:"再次请输入交易密码",
	确认: '确认',
	手机号码不能为空:"手机号码不能为空",
	两次密码不一致:"两次密码不一致",
	请输入验证码:"请输入验证码",
	操作成功:"操作成功",
	"用户名或密码不正确,登录失败":"用户名或密码不正确,登录失败",
	登录成功:"登录成功",
	充值说明:"充值说明",
	请先设置支付密码:"请先设置支付密码",
	复制成功:"复制成功",
	冻结机器人做单本金:"冻结机器人做单本金",
	待审核:"待审核",
	成功:"成功",
	失败:"失败",
	审核中:"审核中",
	在线充值:"在线充值",
	描叙:"描叙",
	银行卡提现:"银行卡提现",
	USDT提现:"USDT提现",
	三级代理:"三级代理",
	一级代理:"一级代理",
	二级代理:"二级代理",
	做单:"做单",
	团队返佣:"团队返佣",
	购买机器人返佣:"购买机器人返佣",
	本金返还:"本金返还",
	时间:"时间",
	机器人做单返还:"机器人做单返还",
	涨幅:"涨幅",
	市场:"市场",
  付款方式:"付款方式",
	我的留言:"我的留言",
  暂无数据: '暂无数据',
  银行卡:'银行卡',
  修改: '修改',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失',
 连接借记卡:"连接借记卡",
 银行名称:'银行名称',
 姓名姓氏: '姓名',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* 重要提示:借记卡信息必须真实有效才能取款。',
 帮助中心:'帮助中心',
 会员须知:'会员须知',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'我知道了',
 链接点击:'链接点击',
 确定:'确定',
 建立自己的团队邀请:'建立自己的团队邀请',
 邀请码:'邀请码',
 加入:'加入',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的机器人对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一台机器人，待确认",
 	向你出售一台机器人:"向你出售一台机器人，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过机器人价格",
 	银行卡已被绑定:"银行卡已被绑定",
 	USDT_TRC20提现:'USDT_TRC20提现',
 	USDT_ERC20提现:'USDT_ERC20提现',
 	银行卡提现:'银行卡提现',
 	邀请赠送:'邀请赠送',
 	机器人返佣:'机器人返佣',
 	升级:'升级',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售机器人,请联系客服了解详情",
   交易编号: "交易编号",
   消息详情:"消息详情",
   个人收益累计:"个人收益累计",
   今日代理收益:"今日代理收益",
   代理累计收益:"代理累计收益",
   机器人返佣:"机器人返佣",
   截图保存推荐给朋友:"截图保存推荐给朋友",
   复制:"复制",
   充值提示1:"提示1:最低充值10USDT起,即可充值,否则不到账",
   充值提示2:"提示2:充值需要链上确认，成功充值后，预计一分钟左右到账钱包余额。",
   请输入正确的充值金额:"请输入正确的充值金额",
   推荐:"推荐",
   充值金额:"充值金额",
   请上传付款截图:"请上传付款截图",
   充值ID提示:"充值ID提示",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。",
	上传凭证:"上传凭证",
	不能为空:"不能为空",
  绑定钱包地址:'绑定钱包地址',
	请输入交易密码:"请输入交易密码",
	备注:"备注",
	请输入银行卡号:"请输入银行卡号",
	请输入你的名字:"请输入你的名字",
	银行卡号:"银行卡号",
	添加银行卡:"添加银行卡",
	请选择银行:"请选择银行",
	请输入钱包地址:"请输入钱包地址",
	钱包地址:"钱包地址",
	"取款须知":"取款须知",
	"须知1":"1. 24小时内使用USDT实时取款（推荐）",
	"须知2":"2.银行卡",
	"须知3":"取款 *取款时间为10:00-18:00",
	"须知4":"*申请非取款时间或跨行取款将于次日10:00-18:00到账",
	我知道了:"我知道了",
	"提现0":"1USDT=1USD=595NGN ERC20",
	"提现1":"提示1：最低单笔提现20美元/天，单笔交易手续费10美元/次",
	"提现2":"RC20提示2：一次性提款最低10美元，一次性交易手续费1美元。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	请输入请款金额:"请输入请款金额",
	已绑定:"已绑定",
	去设置:"去设置",
	汇率:"汇率",
	请输入正确的提现金额:"请输入正确的提现金额",
	提款记录:"提款记录",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包约。",
	请先绑定钱包地址:"请先绑定钱包地址",
	输入邮箱:"输入邮箱",
	指定用户不存在:"指定用户不存在",
	今日涨幅:"今日涨幅",
	快捷充币:"快捷充币",
	快速买USDT:"快速买USDT",
	在线支付:"在线支付",
	自动到账:"自动到账",
	线下支付:"线下支付",
	联系客服获取验证码:"联系客服获取验证码",
	获取:"获取",
	重新登录:"重新登录",
	退出成功:"退出成功",
	不能低于最小充值:"不能低于最小充值",
	不能低于最小提现金额:"不能低于最小提现金额",
	最小提现:"最小提现",
	官方充币:"官方充币",
	银行卡充值:"银行卡充值",
	等待审核:"等待审核",
	可提现金额不足:"可提现金额不足",
	未经授权:"未经授权",
	交易密码不正确:"交易密码不正确",
	提现次数不足:"提现次数不足",
	官网:"官网",
	
	需要更新的银行卡不存在:"需要更新的银行卡不存在",
	银行卡提现:"银行卡提现",
	
	激活金额:"激活金额",
	赠送:"赠送",
	出售:"出售",
	
	注册邮箱已存在:"注册邮箱已存在",
	请输入用户ID:"请输入用户ID",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。",
	请输入出售金额:"请输入出售金额",
	出售中:"出售中",
	 收到一台系统赠送的机器人 :"收到一台系统赠送的机器人",
	 会员出售:"会员出售",
	 "出售待确认中,请勿重复操作":"出售待确认中,请勿重复操作",
	 激活成功:"激活成功",
	恭喜新用户:"恭喜新用户",
	
	
	投资:"投资",
	投资理财:"投资理财",
	投资金额:"投资金额",
	我的投资:"我的投资",
	投资返佣:"投资返佣",
	投资订单:"投资订单",
	运行中:"运行中",
	冻结投资做单本金:"冻结投资做单本金",
	投资做单返还:"投资做单返还",
	"钱包余额不足，启动投资失败":"钱包余额不足，启动投资失败",
	收到一个系统赠送的投资产品:"收到一个系统赠送的投资产品",
	购买投资:"购买投资",
	购买投资增加经验值:"购买投资增加经验值",
	下级购买投资增加经验值:"下级购买投资增加经验值",
	出售价格不能超过投资价格:"出售价格不能超过投资价格",
	"向你赠送一个投资产品，待确认":"向你赠送一个投资产品，待确认",
	"向你出售一个投资产品，待确认":"向你出售一个投资产品，待确认",
	"你出售的投资产品对方已接受,出售所得已打款到您账户,请查收":"你出售的投资产品对方已接受,出售所得已打款到您账户,请查收",
	你出售的投资产品对方已拒绝接受:"你出售的投资产品对方已拒绝接受",
	赠送投资:"赠送投资",
	投资产品:"投资产品",
	请选择投资产品:"请选择投资产品",
	自动投资产品数量:"自动投资产品数量",
	kefu1:"在线客服",
	"邀请好友注册，领取佣金":"邀请好友注册，领取佣金",
	"个人充值红利活动":"个人充值红利活动",
	领取:"领取",
	未满足条件:"未满足条件",
	
	'youtube活动内容':'关于奖金的重要说明：\n1. 用户录制视频并上传到自己的 YouTube  Facebook  等频道，即可领取 1-200 美元奖金。\n2、平台将对您的视频进行审核，并根据视频质量将视频广告费打入您的账户余额。\n3.同一频道同一广告视频只能领取一次奖金，每周可为应用发布新视频。\n4.不得恶意发送视频奖励请求。如果您恶意提交视频奖励请求，您的帐户将被禁止。',
	"Bono de YOUTUBE":"YouTube 奖金",
	"Publicar video bono 10 USDT - 200 USDT":"发布奖励视频10 USDT - 200 USDT",
	"Ingrese el monto de la solicitud":"请输入请求金额",
	"Enlace de tu video":"您的视频链接",
	"Ingrese el enlace del video post-publicado":"输入发布后视频的链接",
	"Aplicar para un premio":"申请奖品",
	"Solicitar una bono":"索取优惠券",
	"URL del vídeo":"视频地址",
	"Tiempo de aplicación":"申请时间",
}
