export default {
  tabbar: {
    首页: 'مسكن',
    市场: 'سوق',
    任务: 'مهمة',
    团队: 'فريق',
    我的: 'لي'
  },
  navbar: {
    充值: 'الوديعة',
    提现: 'انسحاب',
    帮助: 'مساعدة',
    分享: 'شارك',
    活动: 'الأحداث'
  },	 
  payment: {
    付款方式详情: 'تفاصيل طريقة الدفع',
    银行卡: 'بطاقة مصرفية',
    钱包地址: 'عنوان المحفظة',
    交易密码: 'عملية كلمة المرور',
    保存: 'حفظ'
  },
  market: {
    市场: 'سوق',
    名称: 'الاسم',
    价格: 'سعر',
  },
 
  task: {
    任务: 'مهمة',
	语言切换: 'تبديل اللغة',
    我的余额: 'رصيدي',
    增加订单数量: 'زيادة كمية الطلب',
    今日剩余订单: 'الطلبات المتبقية اليوم',
    频率: 'تردد',
    手动交易: 'التداول اليدوي',
    预期收益: 'الدخل المتوقع',
    需要启动存款: 'تحتاج إلى بدء الإيداع',
    任务说明: 'وصف المهام',
    每天可以完成10个任务: 'يمكن أن تكمل 10 مهام في اليوم',
    联系VIP助手增加任务数量: 'اتصل بمساعد VIP لزيادة عدد المهام',
    每个用户的任务总数为300次: 'إجمالي عدد المهام لكل مستخدم 300',
    购买机器人无需人工操作即可自动交易: 'معاملة تلقائية بدون تشغيل يدوي عند شراء الروبوتات',
    我知道了: 'حصلت عليه!'
  },
  团队: 'فريق',
  规则: 'حكم',
  会员级别: 'مستوى العضو',
  经验: 'خبرة',
  代理信息: 'معلومات الوكيل',
  更多: 'المزيد',
  团队奖励: 'مكافآت الفريق',
  昨天: 'في الامس',
  本星期: 'هذا الاسبوع',
  全部: 'الجميع',
  团队收入记录: 'سجلات إيرادات الفريق',
  机器人回扣: 'خصم الروبوت',
  会员体验: 'تجربة الأعضاء',
  个人收入记录: 'سجلات الدخل الشخصي',
  机器人采购: 'شراء الروبوت',
  机器人启动押金: 'إيداع بدء تشغيل الروبوت',
  机器人礼物: 'هدية الروبوت',
  机器人订单: 'ترتيب الروبوت',
  机器人: 'إنسان آلي',
  一次性总和: 'دفعة واحدة',
  总计: 'المجموع',
  我的机器人: 'روبوتاتي',
  被激活: 'مفعل',
  工作中: 'في العمل',
  暂停: 'قف',
  结束: 'نهاية',
  刷新: 'ينعش',
  来源: 'مصدر',
  价格: 'سعر',
  启动押金: 'سعر البداية',
  剩余时间: 'الوقت المتبقي',
  激活时间: 'وقت التنشيط',
  号: 'لا.',
  我的资产: 'بيانات حسابي',
  退出: 'تسجيل خروج',
  邀请码: 'شفرة الدعوة',
  我的钱包余额: 'رصيد محفظتي',
  我的机器人仓库: 'مستودع الروبوت الخاص بي',
  代理信息: 'معلومات الوكيل',
  去提款: 'انسحاب',
  昨天代理返利: 'وكالة خصم أمس',
  累计代理返利: 'عمولة الوكالة المتراكمة',
  今天机器人收益: 'إيرادات اليوم',
  累计机器人收益: 'الدخل المتراكم',
  累计机器人回扣: 'عمولة الروبوت التراكمية',
  累计总回报: 'إجمالي العائد التراكمي',
  自动订单机器人数量: 'عدد روبوتات الطلبات التلقائية',
  我的付款方式: 'طريقة الدفع الخاصة بي',
  个人收入记录: 'سجلات الدخل الشخصي',
  团队收入记录: 'سجلات إيرادات الفريق',
  充值记录: 'سجل الإيداع',
  我的团队成员: 'أعضاء فريقي',
  活动中心: 'مركز الفعاليات',
  修改登录密码: 'تعديل كلمة مرور تسجيل الدخول',
  交易密码管理: 'إدارة كلمة مرور المعاملات',
  机器人: 'إنسان آلي',
  机器人性能: 'أداء الإنسان الآلي',
  机器人价格: 'سعر الروبوت',
 
  有效期: 'فترة الصلاحية',
  
  天: 'أيام',
  去购买: 'للشراء',
  买个机器人: 'شراء الروبوت',
 
  预计日收益: 'الدخل اليومي المقدر',
  启动保证金: 'بدء الإيداع',
  请输入购买数量: 'الرجاء إدخال كمية الشراء',
  个机器人: ' حاسوب / روبوت',
  机器人购买后需要激活才能工作: 'يحتاج الروبوت إلى التنشيط للعمل بعد الشراء',
  机器人激活需要从余额中扣除相应的启动押金:
    'يحتاج تنشيط الروبوت إلى خصم إيداع بدء التشغيل المقابل من الرصيد',
  机器人暂停机器人停止时将返还启动押金:
    'عندما يتوقف الروبوت ، سيتم إرجاع إيداع بدء التشغيل',
	不激活的机器人可以赠送:"يمكن التخلي عن الروبوتات غير النشطة",
  购买: 'شراء',
  点击去了解我们: 'انقر لمعرفة المزيد عنا',
  购买机器人: 'شراء الروبوت',
  快速入口: 'دخول سريع',
  '日/个人受益': 'فائدة يومية / شخصية',
  购买教程: 'شراء البرنامج التعليمي',
  订单数量: 'كمية الطلب',
  我们的合作伙伴: 'شركاؤنا',
  提款: 'انسحاب',
  提款金额: 'كمية السحب ',
  你还没有添加银行卡: 'لم تقم بإضافة بطاقة مصرفية',
  提现说明: 'وصف الانسحاب',
  确认提款: 'تأكيد السحب ',
  钱包余额: 'رصيد المحفظة',
  输入数量:"أدخل مبلغ USDT",
  数量:"مقدار",
  登录: 'تسجيل الدخول',
  输入账号: 'أدخل رقم الحساب',
  输入密码: 'إدخال كلمة السر',
  还没有账号点击注册: 'لا حساب؟ انقر فوق تسجيل',
  忘记密码: 'هل نسيت كلمة السر',
  重新登录: 'تسجيل الدخول مرة أخرى',
  密码找回成功: 'تم استرداد كلمة المرور بنجاح',
  找回成功请重新登录: 'تم الاسترداد بنجاح ، يرجى تسجيل الدخول مرة أخرى',
  确认: 'أكد',
  发送验证码: 'أرسل رمز التحقق',
  再次输入密码: 'أدخل كلمة المرور مرة أخرى',
  输入验证码: 'أدخل رمز التحقق',
  输入手机号: 'أدخل رقم الهاتف المحمول',
  注册: 'يسجل',
  输入用户名: 'أدخل اسم مستخدم واحد',
  请输入邀请码: 'الرجاء إدخال رمز الدعوة',
  返回: 'يعود',
  密码找回失败: 'فشل استرجاع كلمة المرور',
  输入的信息有误: 'تم إدخال معلومات غير صحيحة',
	
	获取验证码:"الحصول على رمز",
	手机号码不能为空:"لا يمكن أن يكون رقم الهاتف المحمول فارغًا",
	账号不存在:"الحساب غير موجود",
	发送成功:"تم الارسال بنجاح",
	今日收益:"أرباح اليوم",
	
	日收益:"الدخل اليومي",
	
	数量不能为空:"لا يمكن أن تكون الكمية فارغة",
	加载中:"جار التحميل",
	唯一码:"كود فريد",
	未激活:"غير نشيط",
	激活:"التنشيط",
	购买时间:"وقت الشراء",
	"钱包余额不足，激活该机器人失败":"رصيد المحفظة غير كافٍ ، فشل في تنشيط الروبوت",
	暂无数据:"لايوجد بيانات...",
	激活时间:"وقت التنشيط",
	运行时长:"وقت التشغيل",
	签约购买:"شراء العقد",
	系统赠送:"هبة النظام",
	状态:"حالة",
	正常:"عادي",
	我的机器人:"الروبوت الخاص بي",
	一级会员:"المستوى 1",
	二级会员:"المستوي 2",
	三级会员:"المستوى 3",
	人:"أشخاص",
	充值客服:"إعادة شحن خدمة العملاء",
	充值:"الوديعة",
	提现:"انسحاب",
	设置交易密码:"قم بتعيين كلمة مرور المعاملة",
	登录密码:"كلمة سر الدخول",
	请输入交易密码:"الرجاء إدخال كلمة مرور المعاملة",
	再次请输入交易密码:"الرجاء إدخال كلمة مرور المعاملة الخاصة بك مرة أخرى",
	确认: 'أكد',
	手机号码不能为空:"لا يمكن أن يكون رقم الهاتف المحمول فارغًا",
	两次密码不一致:"كلمتا المرور غير متسقتين",
	请输入验证码:"الرجاء إدخال رمز التحقق",
	操作成功:"العملية ناجحة",
	"用户名或密码不正确,登录失败":"اسم المستخدم أو كلمة المرور غير صحيحة ، فشل تسجيل الدخول",
	登录成功:"نجح تسجيل الدخول",
	充值说明:"تعليمات إعادة الشحن",
	请先设置支付密码:"يرجى تعيين كلمة مرور الدفع أولاً",
	复制成功:"تم النسخ بنجاح",
	冻结机器人做单本金:"تجميد مبدأ أوامر الروبوت",
	待审核:"لتتم مراجعتها",
	成功:"النجاح",
	失败:"يفشل",
	审核中:"قيد المراجعة",
	在线充值:"إعادة الشحن عبر الإنترنت",
	描叙:"يصف",
	银行卡提现:"سحب البطاقة المصرفية",
	USDT提现:"سحب USDT",
	三级代理:"وكيل المستوى الثالث",
	一级代理:"الوكيل الأساسي",
	二级代理:"الوكيل الثانوي",
	做单:"قم بإصدار أمر",
	团队返佣: "لجنة الفريق",
	购买机器人返佣:"عمولة شراء الروبوت",
	本金返还:"عودة رأس المال",
	时间:"زمن",
	机器人做单返还:"يقوم الروبوت بإصدار أوامر الشراء والإرجاع",
	涨幅:"يزيد",
	
  付款方式:"طريقة الدفع او السداد",
	我的留言:"رسالتي",
  
  银行卡:'بطاقة مصرفية',
  修改: 'يتغير',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'تأكد من صحة عنوان المحفظة ، وذلك لمنع الخسارة التي يسببها المستخدمون الذين لا يستطيعون استلام الأموال بسبب إدخال عنوان خاطئ',
 连接借记卡:"ربط بطاقة الخصم",
 银行名称:'اسم البنك',
 姓名姓氏: 'الاسم',
 IBAN号码:'رقم البطاقة المصرفية',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* هام: يجب أن تكون معلومات بطاقة الخصم صحيحة وصالحة قبل السحب.',
 帮助中心:'مركز المساعدة',
 会员须知:'ملاحظات للأعضاء',
 '24小时内使用USDT实时取款（推荐）':'1. سحب فوري مع الدولار الأمريكي خلال 24 ساعة (موصى به)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. سحب البطاقة المصرفية * وقت السحب هو 10:00 حتي 18:00 * إدخال الحساب في غضون ساعتين بعد بدء السحب * سيتم استلام طلب عدم السحب أو السحب بين البنوك في الساعة 10:00 حتي 18:00 في اليوم التالي',
 我知道了:'حصلت عليه!',
 链接点击:'انقر فوق الارتباط',
 
 建立自己的团队邀请:'إنشاء دعوة الفريق الخاص بك',

 加入:'انضم',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "تم قبول الروبوت الذي قمت ببيعه من قبل الطرف الآخر.تم دفع عائدات البيع إلى حسابك.يرجى المراجعة.",
 	"你出售的机器人对方已拒绝接受":"تم رفض الروبوت الذي قمت ببيعه من قبل الطرف الآخر",
 	向你赠送一台机器人:"يتم تقديم روبوت لك للتأكيد",
 	向你出售一台机器人:"بيع روبوت لك ، ليتم تأكيده",
 	出售价格不能超过机器人价格:"لا يمكن أن يتجاوز سعر البيع سعر الروبوت",
 	银行卡已被绑定:"تم ربط البطاقة المصرفية",
 	USDT_TRC20提现:'USDT_TRC20 سحب',
 	USDT_ERC20提现:'سحب USDT ERC20',
 	银行卡提现:'سحب البطاقة المصرفية',
 	邀请赠送:'هدية دعوة',
 	机器人返佣:'لجنة الروبوت',
 	升级:'رفع مستوى',
 	充值主网:'إعادة شحن الشبكة الرئيسية',
   "您被限制出售机器人,请联系客服了解详情":
     "أنت ممنوع من بيع الروبوتات.الرجاء الأتصال بمركز العملاء للمزيد من التفاصيل",
   交易编号: "رقم المعاملة",
   消息详情:"تفاصيل الرسالة",
   个人收益累计:"الدخل الشخصي المتراكم",
   今日代理收益:"دخل الوكالة اليوم",
   代理累计收益:"الدخل المتراكم من الوكالة",
  
   截图保存推荐给朋友:"حفظ لقطات الشاشة والتوصية للأصدقاء",
   复制:"ينسخ",
   
   请输入正确的充值金额:"الرجاء إدخال مبلغ إعادة الشحن الصحيح",
   推荐:"توصي",
   充值金额:"مبلغ إعادة الشحن",
   请上传付款截图:"يرجى تحميل لقطة شاشة الدفع",
   充值ID提示:"موجه إعادة الشحن",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"يرجى التأكد من صحة عنوان المحفظة.إذا تم ملء عنوان المحفظة بشكل غير صحيح ، سيتحمل المستخدم الخسائر الناجمة عن عدم القدرة على تحصيل الأموال.",
	上传凭证:"تحميل القسيمة",
	不能为空:"لايمكن ان يكون فارغا",
  绑定钱包地址:'ربط عنوان محفظة USDT',
	
	备注:"ملاحظات",
	请输入你的名字:"من فضلك أدخل إسمك",
	请输入银行卡号:"الرجاء إدخال رقم البطاقة المصرفية",
	银行卡号:"رقم البطاقة المصرفية",
	银行:"مصرف",
	添加银行卡:"إضافة بطاقة مصرفية",
	请选择银行:"الرجاء تحديد بنك",
	请输入钱包地址:"الرجاء إدخال عنوان المحفظة",
	钱包地址:"عنوان المحفظة USDT TRC20",
	"取款须知":"تعليمات الانسحاب",
	"须知1":"1. سحب فوري مع الدولار الأمريكي خلال 24 ساعة (موصى به)",
	"须知2":"2.بطاقة مصرفية",
	"须知3":"وقت السحب * هو 10:00 حتي 18:00",
	"须知4":"*سيتم استلام طلب عدم السحب أو السحب بين البنوك في الساعة 10:00 حتي 18:00 في اليوم التالي",
	
	已绑定:"ملزمة",
	去设置:"إعدادات",
	汇率:"سعر الصرف",
	请输入正确的提现金额:"الرجاء إدخال مبلغ السحب الصحيح",
	提款记录:"سجل الانسحاب",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"نصيحة 1: يجب أن يكون عنوان المحفظة المرتبط متسقًا مع محفظة الدفع ، وإلا فلن يتم استلامه。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"نصيحة 2: من المتوقع أن تصل في غضون دقيقة واحدة تقريبًا بعد إعادة الشحن الناجحة.يرجى التحقق من محفظتك.",
	请先绑定钱包地址:"يرجى ربط عنوان المحفظة أولاً",
	输入邮箱:"أدخل بريد جوجل",
	指定用户不存在:"المستخدم المحدد غير موجود",
	今日涨幅:"يزيد",
	快捷充币:"إيداع سريع",
	快速买USDT:"شراء سريع USDT",
	在线支付:"الدفع عبر الإنترنت",
	自动到账:"إيصال تلقائي",
	线下支付:"الدفع دون اتصال بالإنترنت",
	联系客服获取验证码:"اتصل بخدمة العملاء للحصول على رمز التحقق",
	获取:"احصل على",
	重新登录:"تسجيل الدخول مرة أخرى",
	退出成功:"تم الخروج بنجاح",
	不能低于最小充值:"لا يمكن أن يكون أقل من الحد الأدنى لإعادة الشحن",
	不能低于最小提现金额:"ما لا يقل عن الحد الأدنى لمبلغ السحب",
	最小提现:"الحد الأدنى للسحب",
	官方充币:"وديعة رسمية",
	银行卡充值:"إعادة شحن البطاقة المصرفية",
	等待审核:"في انتظار المراجعة",
	可提现金额不足:"مبلغ السحب غير كاف",
	未经授权:"غير معتمد",
	交易密码不正确:"كلمة مرور غير صحيحة للمعاملة",
	提现次数不足:"أوقات السحب غير كافية",
	需要更新的银行卡不存在:"البطاقة المصرفية المراد تحديثها غير موجودة",
	银行卡提现:"سحب البطاقة المصرفية",
	激活金额:"مبلغ التفعيل",
	'赠送/出售':"العطاء / البيع",
	赠送:"يعطي",
	出售:"بيع",
	账户usdt不足:"عدم التوازن",
	请输入提现金额:"الرجاء إدخال مبلغ السحب",
	恭喜新用户:"مبروك للمستخدمين الجدد ",
	kefu1:"يوتيوب فيديو تعليمي",
	登录密码:"كلمة سر الدخول",
	再次请输入交易密码:"أدخل كلمة مرور المعاملة مرة أخرى",
	输入手机号码:"رقم الهاتف",
	输入手机号码:"أدخل رقم الهاتف",
	输入密码:"كلمة المرور",
	请输入密码:"أدخل كلمة المرور",
	手机号格式错误:"خطأ في تنسيق رقم الهاتف المحمول",
	"密码错误次数过多,请等待x秒后再试":"أخطاء كلمة المرور كثيرة جدًا ، يرجى الانتظار 600 ثانية والمحاولة مرة أخرى",
	"此账号已冻结,请联系客服":"تم تجميد هذا الحساب ، يرجى الاتصال بخدمة العملاء",
	登录失败:"فشل تسجيل الدخول",
	请勿重复操作:"عدم تكرار",
	邀请码不正确:"رمز الدعوة غير صحيح",
	此账号已冻结:"تم تجميد هذا الحساب",
	请输入手机号:"الرجاء إدخال رقم هاتفك المحمول",
	验证码错误:"خطأ في رمز التحقق",
	手机号存已存在:"رقم الهاتف المحمول موجود بالفعل",
	注册账号已存在:"الحساب المسجل موجود بالفعل",
	请确定新密码:"يرجى تأكيد كلمة المرور الجديدة",
	请再次输入登录密码:"الرجاء إدخال كلمة مرور تسجيل الدخول الخاصة بك مرة أخرى",
	密码长度不能少于6位:"لا يمكن أن يكون طول كلمة المرور أقل من 6 أرقام",
	
	输入邮箱:"أدخل بريد جوجل",
	注册邮箱已存在:"البريد الإلكتروني للتسجيل موجود بالفعل",
	请输入用户ID:"الرجاء إدخال معرف المستخدم",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"يرجى مراجعة المعلومات بعناية إذا قمت بإدخال اسم حساب الطرف الآخر ونقل الملكية.إذا كان هناك خطأ في التشغيل ، فلا يمكن استرجاعه من خلال النظام أو خدمة عملاء النظام الأساسي.",
	请输入出售金额:"الرجاء إدخال مبلغ البيع",
	出售中:"تم تقديم الهدية ، في انتظار التأكيد",
	 收到一台系统赠送的机器人 :"استقبل روبوت من النظام",
	 会员出售:"يبيع الأعضاء",
	 "出售待确认中,请勿重复操作":"تأكيد معلق للبيع ، لا تكرر العملية",
	 激活成功:"تفعيل النجاح",
	 操作成功:"العملية ناجحة",
	
	输入邮箱:"أدخل بريد جوجل",
	
	请输入登录密码:"الرجاء إدخال كلمة مرور تسجيل الدخول الخاصة بك",
	未满足条件:"الشرط لم تتحقق",
	活动已过期:"انتهى النشاط",
	"邀请好友注册，领取佣金":"قم بدعوة الأصدقاء للتسجيل والحصول على العمولة",
	领取:"لاستقبال",
	团队规则:"قواعد الفريق",
	邮箱已存在:"البريد الإلكتروني موجود بالفعل",
	获取邮箱验证码:"الحصول على رمز",
	请输入邮箱:"الرجاء إدخال عنوان البريد الإلكتروني",
	手机号码:"هاتف",
	邮箱不能为空:"لا يمكن أن يكون البريد الإلكتروني فارغًا",
	"出售/赠送":"بيع / هدية",
	已接受:"قبلت",
	"出售/赠送人":"البائع / المانح",
	"向你赠送一台机器人，待确认":"يتم تقديم روبوت لك للتأكيد",
	国码:"رمز البلد",
	取消:"يلغي",
	银行卡号位数错误:"رقم خاطئ للبطاقات المصرفية",
	官网:"موقع رسمي",
	注册成功:"نجح تسجيل الدخول",
	设置成功:"تم التعيين بنجاح",
	手机号码已存在:"رقم الهاتف المحمول موجود بالفعل",
	修改成功:"تم التعديل بنجاح",
	立即登录:"لديك حساب بالفعل ، انقر لتسجيل الدخول",
	客服:"دردشة",
	邮箱:"البريد الإلكتروني",
	体验机器人不能赠送:"لا يمكن التخلي عن روبوتات الخبرة",
	接受:"يقبل",
	拒绝:"رفض",
	
	
	
	投资:"الاستثمار",
	投资理财:"الاستثمار والإدارة المالية",
	投资金额:"مبلغ الاستثمار",
	我的投资:"استثماراتي",
	投资返佣:"خصم الاستثمار",
	投资订单:"أمر الاستثمار",
	运行中:"الجري",
	冻结投资做单本金:"تجميد أصل أمر الاستثمار",
	投资做单返还:"العائد على أوامر الاستثمار",
	"钱包余额不足，启动投资失败":"رصيد المحفظة غير كافٍ، فشل في بدء الاستثمار",
	收到一个系统赠送的投资产品:"حصل على منتج استثماري كهدية من النظام",
	购买投资:"شراء الاستثمارات",
	购买投资增加经验值:"شراء الاستثمارات يزيد نقاط الخبرة",
	下级购买投资增加经验值:"شراء واستثمار المرؤوسين يزيد من قيمة الخبرة",
	出售价格不能超过投资价格:"لا يجوز أن يتجاوز سعر البيع سعر الاستثمار",
	"向你赠送一个投资产品，待确认":"سأعطيك منتجًا استثماريًا كهدية، في انتظار التأكيد",
	"向你出售一个投资产品，待确认":"بيع منتج استثماري لك، في انتظار التأكيد",
	"你出售的投资产品对方已接受,出售所得已打款到您账户,请查收":"لقد تم قبول المنتج الاستثماري الذي قمت ببيعه من قبل الطرف الآخر، وتم تحويل عائدات البيع إلى حسابك، يرجى التحقق من",
	你出售的投资产品对方已拒绝接受:"تم رفض المنتج الاستثماري الذي قمت ببيعه من قبل الطرف الآخر",
	赠送投资:"استثمار الهدية",
	投资产品:"المنتجات الاستثمارية",
	请选择投资产品:"الرجاء تحديد منتج استثماري",
	自动投资产品数量:"كمية منتج الاستثمار التلقائي",
	
	"youtube活动内容":"ملاحظة مهمة حول المكافآت:\n1. يمكن للمستخدمين الحصول على مكافأة تتراوح بين 1 و 200 دولار عن طريق تسجيل فيديو وتحميله على قنواتهم مثل YouTube و Facebook وغيرها.\n2. ستقوم المنصة بمراجعة الفيديو الخاص بك وإيداع رسوم الإعلان في رصيد حسابك بناءً على جودة الفيديو.\n3. يمكن لنفس القناة ونفس فيديو الإعلان الحصول على مكافأة مرة واحدة فقط، ويمكن نشر مقاطع فيديو جديدة للتطبيق كل أسبوع.\n4. يُحظر إرسال طلبات مكافأة الفيديو بشكل خبيث. إذا قمت بإرسال طلبات خبيثة، فسيتم حظر حسابك.",
	"个人充值红利活动":"نشاط مكافأة إعادة الشحن الشخصية",
	"Bono de YOUTUBE":"مكافأة YouTube",
	"Publicar video bono 10 USDT - 200 USDT": "مكافأة الفيديو العامة 10 USDT - 200 USDT",
	"Ingrese el monto de la solicitud":"الرجاء إدخال مبلغ الطلب",
	"Enlace de tu video": "رابط الفيديو الخاص بك",
	"Ingrese el enlace del video post-publicado":"أدخل الرابط إلى الفيديو بعد النشر",
	"Aplicar para un premio":"طلب الحصول على جوائز",
	"Solicitar una bono":"اطلب قسيمة",
	"URL del vídeo": "عنوان الفيديو",
	"Tiempo de aplicación":"وقت تقديم الطلب",
}
