export default {
  tabbar: {
    首页: 'Beranda',
    市场: 'Pasar',
    任务: 'Tugas',
    团队: 'Tim',
    我的: 'Saya'
  },
  navbar: {
    充值: 'Isi ulang',
    提现: 'Penarikan',
    帮助: 'Tolong',
    分享: 'Bagikan',
    活动: 'Acara'
  },	 
  payment: {
    付款方式详情: 'Detail metode pembayaran',
    银行卡: 'Rekening bank',
    钱包地址: 'Alamat dompet',
    交易密码: 'Kata sandi transaksi',
    保存: 'Kirimkan'
  },
  market: {
    市场: 'Pasar',
    名称: 'Nama',
    价格: 'Harga',
  },
 
  task: {
    任务: 'Tugas',
	语言切换: 'Beralih bahasa',
    我的余额: 'Saldo saya',
	银行:"Bank",
    增加订单数量: 'Tingkatkan jumlah pesanan',
    今日剩余订单: 'Sisa pesanan hari ini',
    频率: 'frekuensi',
    手动交易: 'Perdagangan manual',
    预期收益: 'Pendapatan yang diharapkan',
    需要启动存款: 'Perlu memulai deposit',
    任务说明: 'Deskripsi tugas',
    每天可以完成10个任务: 'Dapat menyelesaikan 10 tugas per hari',
    联系VIP助手增加任务数量: 'Hubungi asisten VIP untuk menambah jumlah tugas',
    每个用户的任务总数为300次: 'Jumlah total tugas per pengguna adalah 300',
    购买机器人无需人工操作即可自动交易: 'Transaksi otomatis tanpa operasi manual saat membeli robot',
    我知道了: 'Saya mengerti!'
  },
  团队: 'Tim',
  规则: 'Aturan',
  会员级别: 'Tingkat anggota',
  经验: 'Pengalaman',
  代理信息: 'Informasi agen',
  更多: 'Lebih',
  团队奖励: 'Penghargaan tim',
  昨天: 'Kemarin',
  本星期: 'Minggu ini',
  全部: 'Semua',
  团队收入记录: 'Catatan pendapatan tim',
  机器人回扣: 'Rabat Trading AI',
  会员体验: 'Pengalaman anggota',
  个人收入记录: 'Catatan pendapatan pribadi',
  机器人采购: 'Pengadaan robot',
  机器人启动押金: 'Deposit awal robot',
  机器人礼物: 'Hadiah robot',
  机器人订单: 'Pesanan robot',
  机器人: 'Robot',
  一次性总和: 'Jumlah bulat',
  总计: 'Total',
  我的机器人: 'Robot saya',
  被激活: 'Diaktifkan',
  工作中: 'Bekerja',
  暂停: 'Berhenti',
  结束: 'Akhir',
  刷新: 'Refresh',
  来源: 'Dari',
  价格: 'Harga',
  启动押金: 'Harga mulai',
  剩余时间: 'Waktu yang tersisa',
  激活时间: 'Waktu aktivasi',
  号: 'No.',
  我的资产: 'Aset saya',
  退出: 'Keluar',
  邀请码: 'Kode',
  我的钱包余额: 'Saldo dompet saya',
  我的机器人仓库: 'Gudang robot saya',
  代理信息: 'Informasi agen',
  去提款: 'Penarikan',
  昨天代理返利: 'Pendapatan Agensi Kemarin',
  累计代理返利: 'Pendapatan agensi kumulatif',
  今天机器人收益: 'Pendapatan hari ini',
  累计机器人收益: 'Akumulasi pendapatan',
  累计机器人回扣: 'Akumulasi rabat',
  累计总回报: 'Total pengembalian kumulatif',
  自动订单机器人数量: 'Jumlah AI pesanan otomatis',
  我的付款方式: 'Metode pembayaran saya',
  个人收入记录: 'Catatan pendapatan pribadi',
  团队收入记录: 'Catatan pendapatan tim',
  充值记录: 'Isi ulang catatan',
  我的团队成员: 'Anggota tim saya',
  活动中心: 'Pusat Acara',
  修改登录密码: 'Ubah kata sandi masuk',
  交易密码管理: 'Manajemen kata sandi transaksi',
  机器人: 'Robot',
  机器人性能: 'Performa robot',
  机器人价格: 'Harga robot',
 
  有效期: 'Masa berlaku',
  
  天: 'hari',
  去购买: 'Membeli',
  买个机器人: 'Beli robot',
 
  预计日收益: 'Taksiran pendapatan harian',
  启动保证金: 'Memulai deposit',
  请输入购买数量: 'Silakan masukkan jumlah pembelian',
  个机器人: ' PCs. / robot',
  机器人购买后需要激活才能工作: 'Robot perlu diaktifkan agar berfungsi setelah pembelian',
  机器人激活需要从余额中扣除相应的启动押金:
    'Aktivasi robot perlu mengurangi setoran awal yang sesuai dari saldo',
  机器人暂停机器人停止时将返还启动押金:
    'Ketika robot berhenti, deposit awal akan dikembalikan',
	不激活的机器人可以赠送:"Robot tidak aktif dapat diberikan",
  购买: 'Membeli',
  点击去了解我们: 'Klik untuk mempelajari tentang kami',
  购买机器人: 'Beli robot',
  快速入口: 'Entri cepat',
  '日/个人受益': 'Harian / personal profit',
  购买教程: 'Beli tutorial',
  订单数量: 'Jumlah pesanan',
  我们的合作伙伴: 'Mitra kami',
  提款: 'Penarikan',
  提款金额: 'Jumlah penarikan ',
  你还没有添加银行卡: 'Anda belum menambahkan Rekening bank',
  提现说明: 'Deskripsi penarikan',
  确认提款: 'Konfirmasi penarikan ',
  钱包余额: 'Saldo dompet',
  输入数量:"Masukkan jumlah USDT",
  数量:"Jumlah",
  登录: 'Masuk log in',
  输入账号: 'Masukkan No.Hp',
  输入密码: 'Masukkan password',
  还没有账号点击注册: 'Tidak ada akun? Klik Daftar',
  忘记密码: 'Lupa?',
  重新登录: 'Masuk lagi',
  密码找回成功: 'Kata sandi berhasil diambil',
  找回成功请重新登录: 'Berhasil diambil, silakan masuk lagi',
  确认: 'Konfirmasi',
  发送验证码: 'Kirim kode verifikasi',
  再次输入密码: 'Masukkan kata sandi lagi',
  输入验证码: 'Masukkan kode verifikasi',
  输入手机号: 'Masukkan No.Hp',
  注册: 'Daftar',
  输入用户名: 'Masukkan satu nama pengguna',
  请输入邀请码: 'Silakan masukkan kode undangan',
  返回: 'Kembali',
  密码找回失败: 'Pengambilan kata sandi gagal',
  输入的信息有误: 'Informasi yang dimasukkan salah',
	
	获取验证码:"Ambil kode",
	手机号码不能为空:"Nomor ponsel tidak boleh kosong",
	账号不存在:"Akun tidak ada",
	发送成功:"Berhasil dikirim",
	今日收益:"Penghasilan hari ini",
	
	日收益:"Pendapatan harian",
	
	数量不能为空:"Kuantitas tidak boleh kosong",
	加载中:"Memuat. . .",
	唯一码:"Pengodean robot",
	未激活:"Tidak aktif",
	激活:"Pengaktifan",
	购买时间:"Waktu pembelian",
	"钱包余额不足，激活该机器人失败":"Saldo dompet tidak mencukupi, gagal mengaktifkan robot",
	暂无数据:"Tidak ada data...",
	激活时间:"Waktu aktivasi",
	运行时长:"Waktu berjalan",
	签约购买:"Pembelian kontrak",
	系统赠送:"Hadiah sistem",
	状态:"Statu",
	正常:"Normal",
	我的机器人:"Robot saya",
	一级会员:"Tingkat 1",
	二级会员:"Tingkat 2",
	三级会员:"Tingkat 3",
	人:"orang",
	充值客服:"Isi ulang layanan pelanggan",
	充值:"Isi ulang",
	提现:"Penarikan",
	设置交易密码:"Setel kata sandi transaksi",
	登录密码:"Login password",
	请输入交易密码:"Silakan masukkan kata sandi transaksi",
	再次请输入交易密码:"Silakan masukkan kembali kata sandi transaksi Anda",
	确认: 'Konfirmasi',
	手机号码不能为空:"Nomor ponsel tidak boleh kosong",
	两次密码不一致:"Kedua kata sandi tidak konsisten",
	请输入验证码:"Silakan masukkan kode verifikasi",
	操作成功:"Operasi berhasil",
	"用户名或密码不正确,登录失败":"Nama pengguna atau kata sandi salah, login gagal",
	登录成功:"Login berhasil",
	充值说明:"Instruksi isi ulang",
	请先设置支付密码:"Silakan setel kata sandi pembayaran terlebih dahulu",
	复制成功:"Salin berhasil",
	冻结机器人做单本金:"Membekukan prinsip perintah robot",
	待审核:"Menunggu keputusan",
	成功:"Sukses",
	失败:"Gagal",
	审核中:"Sedang ditinjau",
	在线充值:"Isi ulang online",
	描叙:"Menggambarkan",
	银行卡提现:"Penarikan Rekening bank",
	USDT提现:"Penarikan USDT",
	三级代理:"Agen tingkat ketiga",
	一级代理:"Agen utama",
	二级代理:"Agen sekunder",
	做单:"Buat pesanan",
	团队返佣: "Komisi tim",
	购买机器人返佣:"Beli komisi robot",
	本金返还:"Pengembalian dana pokok",
	时间:"Waktu",
	机器人做单返还:"Pengembalian Pesanan Robot AI",
	涨幅:"Meningkat",
	
  付款方式:"Cara Pembayaran",
	我的留言:"Pesanku",
  
  银行卡:'Rekening bank',
  修改: 'Merevisi',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'Pastikan alamat dompet sudah benar, untuk mencegah kerugian yang disebabkan oleh pengguna yang tidak dapat menerima uang karena salah input alamat',
 连接借记卡:"Mengikat rekening bank",
 银行名称:'Bank nama',
 姓名姓氏: 'Nama Anda',
 IBAN号码:'Bank rekening No.',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Penting: informasi rekening bank harus benar dan valid sebelum penarikan.',
 帮助中心:'Pusat Bantuan',
 会员须知:'Catatan untuk anggota',
 '24小时内使用USDT实时取款（推荐）':'1. Sistem 24 jam, penarikan real-time menggunakan USDT (disarankan)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. Penarikan Rekening Bank *Waktu penarikan adalah 10:00-18:00 *Deposit dalam waktu 2 jam setelah penarikan dimulai *Ajukan untuk waktu non-penarikan atau penarikan antar bank akan tiba pada 10:00-18:00 keesokan harinya',
 我知道了:'Saya mengerti!',
 链接点击:'Link klick',
 
 建立自己的团队邀请:'Buat undangan tim Anda sendiri',

 加入:'Gabung',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "Robot yang Anda jual telah diterima oleh pihak lain. Hasil penjualan telah dibayarkan ke rekening Anda. Silakan periksa.",
 	"你出售的机器人对方已拒绝接受":"Robot yang Anda jual telah ditolak oleh pihak lain",
 	向你赠送一台机器人:"Robot disajikan kepada Anda untuk konfirmasi",
 	向你出售一台机器人:"Menjual Anda robot, untuk dikonfirmasi",
 	出售价格不能超过机器人价格:"Harga jual tidak boleh melebihi harga robot",
 	银行卡已被绑定:"Rekening Bank telah terikat",
 	USDT_TRC20提现:'USDT_TRC20 Penarikan',
 	USDT_ERC20提现:'USDT_ERC20 Penarikan',
 	银行卡提现:'Rekening Bank Penarikan',
 	邀请赠送:'Hadiah undangan',
 	机器人返佣:'Komisi Robot',
 	升级:'upgrade',
 	充值主网:'Isi ulang jaringan utama',
   "您被限制出售机器人,请联系客服了解详情":
     "Anda dilarang menjual robot. Silahkan hubungi layanan pelanggan untuk rincian",
   交易编号: "No. transaksi",
   消息详情:"Detail pesan",
   个人收益累计:"Akumulasi pendapatan pribadi",
   今日代理收益:"Pendapatan agensi hari ini",
   代理累计收益:"Akumulasi pendapatan dari agensi",
  
   截图保存推荐给朋友:"Simpan tangkapan layar dan rekomendasikan ke teman",
   复制:"Salinan",
   
   请输入正确的充值金额:"Silakan masukkan jumlah isi ulang yang benar",
   推荐:"menyarankan",
   充值金额:"Isi ulang jumlah",
   请上传付款截图:"Silakan unggah tangkapan layar transfer yang berhasil",
   充值ID提示:"Isi ulang ID prompt",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Pastikan alamat dompet sudah benar. Jika alamat dompet tidak diisi dengan benar, pengguna akan menanggung kerugian yang disebabkan oleh ketidakmampuan untuk mengumpulkan uang.",
	上传凭证:"Unggah voucher",
	不能为空:"Tidak boleh kosong",
  绑定钱包地址:'Ikat alamat dompet USDT',
	
	备注:"Catatan",
	请输入你的名字:"Silahkan masukan nama anda",
	请输入银行卡号:"Silakan masukkan No. Rekening Bank",
	银行卡号:"No. Rekening Bank",
	添加银行卡:"Tambahkan Rekening Bank",
	请选择银行:"Silakan pilih bank",
	请输入钱包地址:"Silakan masukkan alamat USDT TRC20",
	钱包地址:"Alamat USDT TRC20",
	"取款须知":"Instruksi untuk penarikan",
	"须知1":"1. Gunakan USDT untuk menarik secara real time (disarankan)",
	"须知2":"2.bank card",
	"须知3":"Withdrawal * withdrawal time is 10:00-18:00",
	"须知4":"*The application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day",
	
	已绑定:"Bound",
	去设置:"Buka pengaturan",
	汇率:"kurs",
	请输入正确的提现金额:"Silakan masukkan jumlah penarikan yang benar",
	提款记录:"Catatan penarikan",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:Alamat dompet terikat harus konsisten dengan dompet pembayaran, jika tidak maka tidak akan diterima。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:Diperkirakan akan tiba dalam waktu sekitar satu menit setelah pengisian ulang berhasil. Silakan periksa dompet Anda.",
	请先绑定钱包地址:"Harap ikat alamat dompet terlebih dahulu",
	输入邮箱:"Masukan email",
	指定用户不存在:"Pengguna yang ditentukan tidak ada",
	今日涨幅:"Change",
	快捷充币:"Deposit Cepat",
	快速买USDT:"Beli cepat USDT",
	在线支付:"Pembayaran online",
	自动到账:"Tanda terima otomatis",
	线下支付:"Pembayaran offline",
	联系客服获取验证码:"Hubungi layanan pelanggan untuk kode verifikasi",
	获取:"Dapatkan",
	重新登录:"Masuk lagi",
	退出成功:"Keluar berhasil",
	不能低于最小充值:"Tidak boleh lebih rendah dari isi ulang minimum",
	不能低于最小提现金额:"Tidak kurang dari jumlah penarikan minimum",
	最小提现:"Penarikan minimal",
	官方充币:"Pengisian resmi",
	银行卡充值:"Isi ulang bank",
	等待审核:"Tinjauan tertunda",
	可提现金额不足:"Jumlah penarikan tidak mencukupi",
	未经授权:"tidak terakreditasi",
	交易密码不正确:"Kata sandi transaksi salah",
	提现次数不足:"Waktu penarikan tidak mencukupi",
	需要更新的银行卡不存在:"Bank yang akan diperbarui tidak ada",
	银行卡提现:"Penarikan bank",
	激活金额:"Jumlah aktivasi",
	'赠送/出售':"Berikan / Jual",
	赠送:"Berikan",
	出售:"Jual",
	账户usdt不足:"Akun usdt tidak mencukupi",
	请输入提现金额:"Silakan masukkan jumlah penarikan",
	恭喜新用户:"Selamat untuk pengguna baru ",
	kefu1:"Video tutorial Youtube",
	登录密码:"Login password",
	再次请输入交易密码:"Masukkan kembali kata sandi transaksi",
	输入手机号码:"No.Hp",
	输入手机号码:"Masukkan No.Hp Anda",
	输入密码:"Password",
	请输入密码:"Masukkan password",
	手机号格式错误:"Kesalahan format nomor ponsel",
	"密码错误次数过多,请等待x秒后再试":"Terlalu banyak kesalahan kata sandi, Mohon tunggu 600 detik dan coba lagi",
	"此账号已冻结,请联系客服":"Akun ini telah dibekukan, silakan hubungi layanan pelanggan",
	登录失败:"Gagal masuk",
	请勿重复操作:"Jangan ulangi",
	邀请码不正确:"Kode undangan salah",
	此账号已冻结:"Akun ini telah dibekukan",
	请输入手机号:"Silakan masukkan nomor ponsel Anda",
	验证码错误:"Kesalahan kode verifikasi",
	手机号存已存在:"Nomor ponsel sudah ada",
	注册账号已存在:"Akun terdaftar sudah ada",
	请确定新密码:"Harap konfirmasi kata sandi baru",
	请再次输入登录密码:"Silakan masukkan kata sandi login Anda lagi",
	密码长度不能少于6位:"Panjang kata sandi tidak boleh kurang dari 6 digit",
	
	输入邮箱:"Masukan email",
	注册邮箱已存在:"Email pendaftaran sudah ada",
	请输入用户ID:"Masukkan GMAIL penerima",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"Harap tinjau informasi dengan cermat jika Anda memasukkan nama akun pihak lain dan mentransfer properti. Jika ada kesalahan operasi, itu tidak dapat diambil melalui sistem atau layanan pelanggan platform.",
	请输入出售金额:"Silakan masukkan jumlah penjualan",
	出售中:"Hadiah sudah terkirim, menunggu konfirmasi",
	 收到一台系统赠送的机器人 :"Menerima robot dari sistem",
	 会员出售:"Anggota menjual",
	 "出售待确认中,请勿重复操作":"Penjualan menunggu konfirmasi, jangan ulangi operasi",
	 激活成功:"Aktifkan kesuksesan",
	 操作成功:"Operasi berhasil",
	
	输入邮箱:"Masukan email",
	
	请输入登录密码:"Silakan masukkan kata sandi masuk Anda",
	未满足条件:"Kondisi tidak terpenuhi",
	活动已过期:"Aktivitas berakhir",
	"邀请好友注册，领取佣金":"Undang teman untuk mendaftar dan menerima komisi",
	领取:"Menerima",
	团队规则:"Aturan tim",
	邮箱已存在:"EMAIL sudah ada",
	获取邮箱验证码:"Ambil kode",
	请输入邮箱:"Silakan masukkan alamat email",
	手机号码:"No.Hp",
	邮箱不能为空:"Email tidak boleh kosong",
	"出售/赠送":"Penjualan / Hadiah",
	已接受:"Diterima",
	"出售/赠送人":"Penjual / pemberi",
	"向你赠送一台机器人，待确认":"Robot disajikan kepada Anda untuk konfirmasi",
	国码:"Kode negara",
	取消:"Batal",
	银行卡号位数错误:"Nomor rekening bank salah",
	官网:"Situs resmi",
	注册成功:"Login berhasil",
	设置成功:"Setel berhasil",
	手机号码已存在:"Nomor ponsel sudah ada",
	修改成功:"Berhasil dimodifikasi",
	立即登录:"Sudah punya akun, klik untuk masuk",
	客服:"Chat",
	邮箱:"Email",
	体验机器人不能赠送:"Robot pengalaman tidak dapat diberikan",
	接受:"Menerima",
	拒绝:"Tolak",
	
	
	投资:"Pelaburan",
	投资理财:"Pelaburan dan Pengurusan Kewangan",
	投资金额:"Amaun Pelaburan",
	我的投资:"Pelaburan Saya",
	投资返佣:"Rebat Pelaburan",
	投资订单:"Pesanan Pelaburan",
	运行中:"Berlari",
	冻结投资做单本金:"Membekukan Prinsipal Pesanan Pelaburan",
	投资做单返还:"Pulangan Pesanan Pelaburan",
	"钱包余额不足，启动投资失败":"Baki Dompet Tidak Mencukupi, Gagal Memulakan Pelaburan",
	收到一个系统赠送的投资产品:"Menerima Produk Pelaburan yang Diberikan oleh Sistem",
	购买投资:"Pelaburan Belian",
	购买投资增加经验值:"Beli Pelaburan Meningkatkan Mata Pengalaman",
	下级购买投资增加经验值:"Orang bawahan membeli pelaburan untuk meningkatkan mata pengalaman",
	出售价格不能超过投资价格:"Harga jualan tidak boleh melebihi harga pelaburan",
	"向你赠送一个投资产品，待确认":"Saya akan memberikan anda produk pelaburan sebagai hadiah, menunggu pengesahan",
	"向你出售一个投资产品，待确认":"Menjual produk pelaburan kepada anda, menunggu pengesahan",
	"你出售的投资产品对方已接受,出售所得已打款到您账户,请查收":"Produk pelaburan yang anda jual telah diterima oleh pihak yang satu lagi dan hasil jualan telah dipindahkan ke akaun anda, sila semak",
	你出售的投资产品对方已拒绝接受:"Produk pelaburan yang anda jual telah ditolak oleh pihak lain",
	赠送投资:"Pelaburan percuma",
	投资产品:"Produk pelaburan",
	请选择投资产品:"Sila pilih produk pelaburan",
	自动投资产品数量:"Bilangan produk pelaburan automatik",
	
	"youtube活动内容":"Catatan Penting Tentang Bonus:\n1. Pengguna dapat menerima bonus sebesar $1-200 dengan merekam dan mengunggah video ke saluran YouTube, Facebook, atau saluran lainnya.\n2. Platform akan meninjau video Anda dan mengirimkan biaya iklan video ke saldo akun Anda berdasarkan kualitas video.\n3. Saluran dan video iklan yang sama hanya dapat menerima bonus satu kali, tetapi video baru untuk aplikasi dapat diposting setiap minggu.\n4. Dilarang mengirimkan permintaan bonus video dengan niat buruk. Jika Anda mengajukan permintaan bonus video dengan niat buruk, akun Anda akan diblokir.",
	"Bono de YOUTUBE":"Bonus YouTube",
	"Publicar video bono 10 USDT - 200 USDT":"Bono video awam 10 USDT - 200 USDT",
	"Ingrese el monto de la solicitud":"Sila masukkan jumlah permintaan",
	"Enlace de tu video":"Pautan video anda",
	"Ingrese el enlace del video post-publicado":"Masukkan pautan ke video pasca-tersiar",
	"Aplicar para un premio":"Permohonan untuk hadiah",
	"Solicitar una bono":"Minta Kupon",
	"URL del vídeo":"Alamat video",
	"Tiempo de aplicación":"Masa permohonan",
	
	"Bono de YOUTUBE":"Bonus YouTube",
	"Bono video awam 10 USDT - 200 USDT":"bono video awam 10 USDT - 200 USDT",
	"Ingrese el monto de la solicitud":"Sila masukkan jumlah permintaan",
	"Enlace de tu video":"Pautan video anda",
	"Ingrese el enlace del video post-publicado":"Masukkan pautan ke video pasca-tersiar",
	"Aplicar para un premio":"Permohonan untuk hadiah",
	"Solicitar una bono":"Minta Kupon",
	"URL del vídeo":"alamat video",
	"Tiempo de aplicación":"Masa permohonan",
	"个人充值红利活动":"Aktiviti bonus cas semula peribadi",
}
//印度尼西亚语